import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { MdGetApp } from "react-icons/md";
import Table from "../../components/Datatable/SearchFilterTable"; // Adjust import as needed
import Dropdown from "../../components/DynamicComponents/DynamicDropdown";
import DynamicButton from "../../components/DynamicComponents/DynamicButton";
import DynamicDatePicker from "../../components/DynamicComponents/DynamicDatePicker";
import Loader from "../../utils/Loader";
import { BASE_URI } from "../../utils/BaseURL";

const SalesRegister = () => {
  const [rows, setRows] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [agentNames, setAgentNames] = useState([]);
  const [partyNames, setPartyNames] = useState([]);
  const [dropdown1Value, setDropdown1Value] = useState("All");
  const [dropdown2Value, setDropdown2Value] = useState("All");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [totalTaxableValue, setTotalTaxableValue] = useState(0);
  const [totalBillAmount, setTotalBillAmount] = useState(0);
  const [showLoader, setShowLoader] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [selectedDate, setSelectedDate] = useState([new Date(), new Date()]); // Initialize with current date

  const navigate = useNavigate();
  const token = Cookies.get("token");
  const targetRoute = "Sales Register";
  const menuValue = JSON.parse(localStorage.getItem("Menu"));
  const Depot = JSON.parse(localStorage.getItem("Depo"));
  const selectedLocation = localStorage.getItem("selectedLocation");
  const targetPage = menuValue.find((item) => item.FormName === targetRoute);
  const FormKey = targetPage ? targetPage.FormKey : null;
  const itemFromLocalStorage = JSON.parse(localStorage.getItem("userInfo"));
  const UserName = localStorage.getItem("UserName");
  const LogId = itemFromLocalStorage[0].LogID;
  const typeOfUser = itemFromLocalStorage[0].UserType;

  let DPCode = Depot.find((depot) => depot.DPName === selectedLocation)?.DPCode;

  const config = {
    headers: {
      "Content-Type": "application/json",
      token: token,
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch agents data when the component mounts
        const requestBodyAgents = {
          LogID: LogId,
          FormKey: FormKey,
          Depo: DPCode,
        };
        const responseAgents = await axios.post(
          `${BASE_URI}/agents`,
          requestBodyAgents,
          config
        );
        const agentsData = responseAgents.data.resultWebAgentList.map(
          (agent) => ({
            value: agent.Aname,
            label: agent.Aname,
          })
        );
        setAgentNames([{ value: "All", label: "All" }, ...agentsData]);

        // Fetch parties data when the component mounts
        const requestBodyParties = {
          LogID: LogId,
          FormKey: FormKey,
          Depo: DPCode,
          AgentName: dropdown1Value === "All" ? "" : dropdown1Value,
        };
        const responseParties = await axios.post(
          `${BASE_URI}/parties`,
          requestBodyParties,
          config
        );
        const partyNamesData = responseParties.data.resultWebPartyList.map(
          (party) => ({
            value: party.LName,
            label: party.LName,
          })
        );
        setPartyNames([{ value: "All", label: "All" }, ...partyNamesData]);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          alert("Your Token is expired. Please log in again!");
          navigate("/login");
        } else {
          alert("Something went wrong. Please try again later.");
        }
        console.error("Error fetching data:", error);
      } finally {
        setShowLoader(false);
      }
    };

    fetchData();
  }, [LogId, FormKey, DPCode, navigate, dropdown1Value]);

  useEffect(() => {
    const fetchParties = async () => {
      try {
        const requestBodyParties = {
          LogID: LogId,
          FormKey: FormKey,
          Depo: DPCode,
          AgentName: dropdown1Value === "All" ? "" : dropdown1Value,
        };
        const responseParties = await axios.post(
          `${BASE_URI}/parties`,
          requestBodyParties,
          config
        );
        const partyNamesData = responseParties.data.resultWebPartyList.map(
          (party) => ({
            value: party.LName,
            label: party.LName,
          })
        );
        setPartyNames([{ value: "All", label: "All" }, ...partyNamesData]);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          alert("Your Token is expired. Please log in again!");
          navigate("/login");
        } else {
          alert("Something went wrong. Please try again later.");
        }
        console.error("Error fetching parties:", error);
      }
    };

    if (dropdown1Value !== "All") {
      fetchParties();
    }
  }, [dropdown1Value, LogId, FormKey, DPCode, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setShowLoader(true);

    const formattedStartDate = `${startDate.getFullYear()}${String(
      startDate.getMonth() + 1
    ).padStart(2, "0")}${String(startDate.getDate()).padStart(2, "0")}`;
    const formattedEndDate = `${endDate.getFullYear()}${String(
      endDate.getMonth() + 1
    ).padStart(2, "0")}${String(endDate.getDate()).padStart(2, "0")}`;

    try {
      const requestData = {
        agentName: dropdown1Value === "All" ? "" : dropdown1Value,
        partyName:
          typeOfUser === 3
            ? UserName
            : dropdown2Value === "All"
            ? ""
            : dropdown2Value,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        LogID: LogId,
        Depo: DPCode,
      };

      const response = await axios.post(
        `${BASE_URI}/sales-register`,
        requestData,
        config
      );
      const userData = response.data.resultSalesReg.map((row, index) => ({
        ...row,
        id: index + 1,
      }));

      if (userData.length === 0) {
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 1000);
      }
      const totalTaxable = userData.reduce((acc, row) => acc + row.SaleAmt, 0);
      const totalBill = userData.reduce((acc, row) => acc + row.BillAmt, 0);
      setTotalTaxableValue(totalTaxable.toFixed(2));
      setTotalBillAmount(totalBill.toFixed(2));
      const totalsRow = {
        id: "",
        BillRmk: "",
        Aname: "",
        LName: "Total",
        SaleAmt: totalTaxable.toFixed(2),
        BillAmt: totalBill.toFixed(2),
        Tname: "",
        Package: "",
        PDFFileName: "",
        isTotalRow: true, // Add a flag to identify the total row
      };
      setFiltered([...userData, totalsRow]);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Your Token is expired. Please log in again!");
        navigate("/login");
      } else {
        alert("Something went wrong. Please try again later.");
      }
      console.error("Error submitting form:", error);
    } finally {
      setShowLoader(false);
    }
  };

  const handleDropdown1Change = (event) => {
    setDropdown1Value(event.target.value);
  };

  const handleDropdown2Change = (event) => {
    setDropdown2Value(event.target.value);
    setRows([]); // Clear table data when dropdown value changes
  };

  const handleDateChange = (index, date) => {
    const newDates = [...selectedDate];
    newDates[index] = date;
    setSelectedDate(newDates);
    // Also update the startDate and endDate states if needed
    if (index === 0) setStartDate(date);
    if (index === 1) setEndDate(date);
    setRows([]); // Clear table data when date changes
  };

  const columns = [
    {
      Header: "S No",
      accessor: "id",
      Cell: ({ row }) => (
        <div style={{ textAlign: "center" }}>
          {row.original.isTotalRow ? "" : row.index + 1}
        </div>
      ),
      isInitiallyVisible: true,
    },
    {
      Header: "Bill No",
      accessor: "BillRmk",
      width: 180,
      Cell: ({ value }) => <div style={{ width: "100px" }}>{value}</div>,
    },
    {
      accessor: "BDate",
      Header: "Bill Date",
      width: 100,
      align: "center",
      headerAlign: "center",
      Cell: ({ value }) => <div style={{ width: "100px" }}>{value}</div>,
    },
    {
      Header: "Agent Name",
      accessor: "Aname",
      width: 130,
      Cell: ({ value }) => <div style={{ width: "100px" }}>{value}</div>,
      isInitiallyVisible: false,
    },
    {
      Header: "Party Name",
      accessor: "LName",
      width: 250,
      Cell: ({ value }) => <div style={{}}>{value}</div>,
      isInitiallyVisible: true,
    },
    {
      Header: "Taxable Value",
      accessor: "SaleAmt",
      width: 100,
      Cell: ({ value }) => (
        <div style={{ width: "130px", textAlign: "right" }}>
          {parseFloat(value).toFixed(2)}
        </div>
      ),
      isInitiallyVisible: false,
    },
    {
      Header: "Bill Value",
      accessor: "BillAmt",
      width: 100,
      Cell: ({ value }) => (
        <div style={{ textAlign: "right" }}>{parseFloat(value).toFixed(2)}</div>
      ),
      isInitiallyVisible: true,
    },
    {
      Header: "Transport",
      accessor: "Tname",
      width: 100,
      Cell: ({ value }) => <div style={{ width: "100px" }}>{value}</div>,
      isInitiallyVisible: false,
    },
    {
      Header: "Package",
      accessor: "Package",
      width: 70,
      isInitiallyVisible: false,
    },
    {
      Header: "PDF",
      accessor: "PDFFileName",
      // Cell: ({ row }) => {
      //   const { PDFFileName } = row.values;
      //   return (
      //     <div className='flex items-center'>
      //       {PDFFileName ? (
      //         <MdGetApp
      //           size={25}
      //           onClick={() => handleDownload(row.values)}
      //           style={{ cursor: "pointer" }}
      //         />
      //       ) : null}
      //     </div>
      //   );
      // },
      Cell: (params) => (
        <div className='flex items-center'>
          <MdGetApp
            size={25}
            onClick={
              params.row.values.PDFFileName === ""
                ? null
                : () => handleDownload(params.row.values)
            }
            className={`cursor-pointer text-${
              params.row.values.PDFFileName == "" ? "red" : "blue"
            }-600 ${
              params.row.values.PDFFileName == ""
                ? "red"
                : "hover:text-green-600"
            }  m-2 mt-3`}
          />
        </div>
      ),
      isInitiallyVisible: true,
    },
  ];

  const handleDownload = (row) => {
    if (row.PDFFileName) {
      window.open(row.PDFFileName, "_blank");
    } else {
      console.error("PDF file not found");
    }
  };

  useEffect(() => {
    document.title = "PTAC ERP || Sale Register";

    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className='container mx-auto p-4'>
      {showLoader && <Loader />}
      <h1 className='text-xl text-center font-bold mb-2'>
        {targetPage.FormName}
      </h1>
      <form onSubmit={handleSubmit} className='space-y-2'>
        <div className='grid grid-cols-2 md:grid-cols-2 lg:grid-cols-5 gap-4'>
          <Dropdown
            label='Agent Name'
            options={agentNames}
            value={dropdown1Value}
            onChange={handleDropdown1Change}
          />
          <Dropdown
            label='Party Name'
            options={partyNames}
            value={dropdown2Value}
            onChange={handleDropdown2Change}
          />
          <DynamicDatePicker
            DateLabel='Start Date'
            selectedDate={selectedDate[0]}
            maxDate={new Date()}
            dateFormat='dd/MM/yyyy'
            onDateChange={(date) => handleDateChange(0, date)}
          />
          <DynamicDatePicker
            DateLabel='End Date'
            selectedDate={selectedDate[1]}
            maxDate={new Date()}
            dateFormat='dd/MM/yyyy'
            onDateChange={(date) => handleDateChange(1, date)}
          />
        </div>
        <div className='grid justify-end'>
          <DynamicButton variant='primary' label='Submit' />
        </div>
      </form>
      <div className=''>
        <Table columns={columns} data={filtered} />
      </div>
    </div>
  );
};

export default SalesRegister;
