import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import DebtorList from "../../pages/SubPages/DebtorList";
import Ledger from "../../pages/SubPages/Ledger";
import StockSummary from "../../pages/SubPages/StockSummary";
import ReceivableBills from "../../pages/SubPages/ReceivableBills";
import PayableBills from "../../pages/SubPages/CreditorsBills";
import DispatchReport from "../../pages/SubPages/DispatchReport";
import SaleReportMonthly from "../../pages/SubPages/SaleReportMonthly";
import BalanceSaleOrderConsolidated from "../../pages/SubPages/BalanceSaleOrderConsolidated";
import BalancePurchaseOrderConsolidated from "../../pages/SubPages/BalancePurchaseOrderConsolidated";
import ItemRegister from "../../pages/SubPages/ItemRegister";
import SalesRegister from "../../pages/SubPages/SalesRegister";
import SaleOrder from "../../pages/SubPages/SaleOrder";
import PartyVerification from "../../pages/SubPages/PartyVerification";
import DashboardHome from "../../pages/DashboardHome";
import RetailMaster from "../../pages/SubPages/RetailMaster";
import DistributorPermission from "../../pages/SubPages/DistributorPermission";
import Login from "../Auth/Login";
import PageNotFound from "../../pages/404";

const Content = () => {
  return (
    <Routes>
      <Route path='/home' element={<DashboardHome />} />
      <Route path='/debtors-list' element={<DebtorList />} />
      <Route path='/ledger' element={<Ledger />} />
      <Route path='/stock-summary' element={<StockSummary />} />
      <Route
        path='/bills-outstanding-[receivable]'
        element={<ReceivableBills />}
      />
      <Route path='/bills-outstanding-[payable]' element={<PayableBills />} />
      <Route path='/dispatch-report' element={<DispatchReport />} />
      <Route path='/sale-report-monthly' element={<SaleReportMonthly />} />
      <Route
        path='/balance-sale-order-consolidated-(routine)'
        element={<BalanceSaleOrderConsolidated />}
      />
      <Route
        path='/balance-purchase-order-(consolidated)'
        element={<BalancePurchaseOrderConsolidated />}
      />
      <Route path='/item-register' element={<ItemRegister />} />
      <Route path='/sales-register' element={<SalesRegister />} />
      <Route path='/sale-order' element={<SaleOrder />} />
      <Route path='/party-verification' element={<PartyVerification />} />
      <Route path='/retailer-master' element={<RetailMaster />} />
      <Route path='/permission' element={<DistributorPermission />} />
      <Route path='/*' element={<PageNotFound />} />

      <Route path='/' element={<Navigate to='/login' />} />
    </Routes>
  );
};

export default Content;
