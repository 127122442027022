import React, { useEffect, useState } from "react";
import QuickLinks from "./QuickLinks";
import DoughnutChartComponent from "./SubPages/PieChart";
import Loader from "../utils/Loader";
import QrBarcodeGenerator from "./SubPages/QRCodeGenerator";
import { Link } from "react-router-dom";

const Home = () => {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    document.title = "PTAC ERP || Dashboard";

    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  let typeOfUser = userInfo[0].UserType;

  return (
    <>
      <div
        className={`grid w-full gap-2 ${
          typeOfUser !== 3 ? "grid-cols-1 lg:grid-cols-3" : "grid-cols-1"
        }`}
      >
        {showLoader && <Loader />}

        {typeOfUser !== 3 && (
          <div className='col-span-1 bg-white p-2 rounded-xl shadow-md'>
            <DoughnutChartComponent />
          </div>
        )}

        <div
          className={`${
            typeOfUser !== 3 ? "col-span-2" : "col-span-1"
          } bg-white p-2 rounded-xl shadow-md`}
        >
          <QuickLinks />
        </div>
        {/*<Link to='/dashboard/permission'>
          <button className='px-3 py-2 rounded-lg bg-blue-600 '>Click Me</button>
          </Link>*/}
      </div>
      {/*<QrBarcodeGenerator />
       */}
    </>
  );
};

export default Home;
