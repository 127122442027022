// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';

// const dummyUser = {
//   username: 'admin',
//   password: 'admin123',
//   role: 'admin'
// };

// const Login = () => {
//   const [username, setUsername] = useState('');
//   const [password, setPassword] = useState('');
//   const navigate = useNavigate();

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (username === dummyUser.username && password === dummyUser.password) {
//       localStorage.setItem('user', JSON.stringify(dummyUser));
//       navigate('/dashboard/page1');
//     } else {
//       alert('Invalid credentials');
//     }
//   };

//   return (
//     <div className="h-screen flex justify-center bg-gray-200 items-center">
//       <form onSubmit={handleSubmit} className=" p-6 bg-white rounded shadow-md">
//         <h2 className="text-2xl mb-4">Login</h2>
//         <input
//           type="text"
//           value={username}
//           onChange={(e) => setUsername(e.target.value)}
//           placeholder="Username"
//           className="mb-2 p-2 border rounded w-full"
//         />
//         <input
//           type="password"
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//           placeholder="Password"
//           className="mb-4 p-2 border rounded w-full"
//         />
//         <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded">
//           Login
//         </button>
//       </form>
//     </div>
//   );
// };

// export default Login;

import React, { useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import axios from "axios";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/Logo.jpg";
import FrontImage from "../../assets/SplashImage.jpg";
import Loader from "../../utils/Loader";

const api2 = "https://erp.srssoftwares.in/api/v1";

const Login = ({ handleLogin }) => {
  const [step, setStep] = useState(1);
  const [LogID, setLogID] = useState("");
  const [password, setPassword] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [errors, setErrors] = useState({});
  const [loginError, setLoginError] = useState("");
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false); // State for loading indicator
  const [showLoader, setShowLoader] = useState(true);
  const BASE_URL = api2;
  const navigate = useNavigate();

  const fadeIn = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 200,
  });
  const slideIn = useSpring({
    from: { transform: "translateY(50px)" },
    to: { transform: "translateY(0)" },
    delay: 400,
  });

  useEffect(() => {
    if (loginError) {
      const timer = setTimeout(() => {
        setLoginError("");
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [loginError]);

  const handleLoginClick = async () => {
    if (step === 1) {
      const newErrors = {};
      if (!LogID) newErrors.LogID = "LogID is required";
      if (!password) newErrors.password = "Password is required";

      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
      } else {
        setErrors({});
        setLoading(true); // Show loader
        setShowLoader(true);
        const credentials = {
          LogID: LogID.toUpperCase(),
          LogPwd: password,
        };

        try {
          const response = await axios.post(
            `${BASE_URL}/auth/login`,
            credentials
          );
          console.log(response.data);
          setResponse(response.data);
          const userData = response.data.responseData;
          const token = response.data.token;

          if (userData && userData.Branch) {
            const userAgent = navigator.userAgent;
            console.log("User Agent:", userAgent);
            localStorage.setItem("token", token);
            localStorage.setItem("userInfo", JSON.stringify(userData.UserID));
            localStorage.setItem("Depo", JSON.stringify(userData.Branch));
            Cookies.set("token", token, { expires: 4 / 24 });
            setStep(2);
          } else {
            setLoginError("Incorrect credentials");
          }
        } catch (error) {
          console.error("Error logging in:", error);
          if (error.response && error.response.status === 450) {
            setLoginError("Wrong Credentials");
          } else {
            setLoginError("Internal Server Error. Please try again later.");
          }
        } finally {
          setLoading(false); // Hide loader
          setShowLoader(false);
        }
      }
    } else {
      const newErrors = {};
      if (!selectedLocation) newErrors.selectedLocation = "Branch is required";
      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
      } else {
        setErrors({});
        setLoading(true); // Show loader
        setShowLoader(true);

        let DPCode;
        const Depo = JSON.parse(localStorage.getItem("Depo"));
        Depo.forEach((depo) => {
          if (depo.DPName === selectedLocation) {
            DPCode = depo.DPCode;
          }
        });

        const token = Cookies.get("token");
        const config = {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        };

        const credentials = {
          LogID: LogID.toUpperCase(),
          Depo: DPCode,
        };

        try {
          const response = await axios.post(
            `${BASE_URL}/auth/setNewBranch`,
            credentials,
            config
          );
          const LogNo = response.data.LogNo;
          const menu = response.data.formDetails;
          handleLogin();
          if (response.data && response.data.formDetails) {
            localStorage.setItem("LogValue", LogNo);
            localStorage.setItem("Menu", JSON.stringify(menu));
            localStorage.setItem("selectedLocation", selectedLocation);
            localStorage.setItem("cName", userData.CName);
            localStorage.setItem("isLoggedIn", true);
            navigate("/dashboard/home");
            setStep(2);
          } else {
            setLoginError(
              "Error during branch selection. Please try again later."
            );
          }
        } catch (error) {
          console.error("Error during branch selection:", error);
          if (error.response && error.response.status === 450) {
            setLoginError("Wrong Credentials");
          } else {
            setLoginError("Internal Server Error. Please try again later.");
          }
        } finally {
          setLoading(false); // Hide loader
          setShowLoader(false);
        }
      }
    }
  };

  let userData = JSON.parse(localStorage.getItem("userInfo"));
  if (userData) {
    userData = userData[0];
  }

  const userDepot = JSON.parse(localStorage.getItem("Depo"));

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  useEffect(() => {
    document.title = "PTAC ERP || Login";
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div className='min-h-screen flex items-center justify-center bg-gradient-to-r from-[#fff] via-[#4FA9D7] to-[#fff]'>
        {showLoader && <Loader />}
        <div className='flex flex-col md:flex-row  max-w-7xl w-full bg-white rounded-2xl shadow-2xl overflow-hidden'>
          <div className='w-[50%] md:w-1/2  mx-auto rounded-2xl flex justify-center items-center'>
            <img
              src={step === 1 ? FrontImage : userData?.Logo || Logo}
              loading='lazy'
              alt='Loading Error'
              className='object-cover max-w-full h-auto rounded-2xl shadow-lg bg-white'
            />
          </div>

          <animated.div
            style={fadeIn}
            className='w-full md:w-1/2 p-8 shadow-2xl'
          >
            <animated.h1
              style={slideIn}
              className='text-3xl font-bold text-center mb-8'
            >
              {step === 1 ? (
                <img
                  src={Logo}
                  className='h-[50px] rounded-2xl w-full object-contain'
                />
              ) : (
                <h1 className='text-[#4FA9D7] text-2xl'>{userData?.CName}</h1>
              )}
            </animated.h1>
            {step === 1 ? (
              <form className=''>
                <div className='mb-4'>
                  <label
                    className='block text-black font-bold mb-2'
                    htmlFor='LogID'
                  >
                    LogID
                  </label>
                  <input
                    className='w-full px-3 py-2 text-black border rounded-lg focus:outline-none'
                    id='LogID'
                    type='text'
                    placeholder='Enter your LogID'
                    value={LogID}
                    onChange={(e) => setLogID(e.target.value)}
                  />
                  {errors.LogID && (
                    <p className='text-red-500 text-sm'>{errors.LogID}</p>
                  )}
                </div>
                <div className='mb-4'>
                  <label
                    className='block text-black font-bold mb-2'
                    htmlFor='password'
                  >
                    Password
                  </label>
                  <input
                    className='w-full px-3 py-2 text-black border rounded-lg focus:outline-none'
                    id='password'
                    type='password'
                    placeholder='Enter your password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {errors.password && (
                    <p className='text-red-500 text-sm'>{errors.password}</p>
                  )}
                </div>
              </form>
            ) : (
              <form>
                <div className='mb-4'>
                  <label
                    className='block text-black font-bold mb-2'
                    htmlFor='location'
                  >
                    Select Your Branch
                  </label>
                  <select
                    className='w-full px-3 py-2 text-black border rounded-lg focus:outline-none'
                    id='location'
                    value={selectedLocation}
                    onChange={handleLocationChange}
                  >
                    <option value=''>Select your branch</option>
                    {userDepot.map((location, index) => (
                      <option key={index} value={location.DPName}>
                        {location.DPName}
                      </option>
                    ))}
                  </select>
                </div>
              </form>
            )}
            {loginError && (
              <p className='text-red-500 text-center'>{loginError}</p>
            )}
            <button
              className='w-full bg-[#4FA9D7] text-white font-bold py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300'
              type='button'
              onClick={handleLoginClick}
              disabled={loading} // Disable button when loading
            >
              {loading ? "Loading..." : step === 1 ? "Verify" : "Login"}
            </button>
            <div className='mt-8 text-justify'>
              <p className='text-gray-600'>
                {step === 1 ? (
                  <div>
                    Welcome to{" "}
                    <Link to='https://srssoftwares.com'>
                      <span className='text-blue-600 font-semibold underline text-md'>
                        SRS Softwares
                      </span>
                    </Link>
                    , your solution for comprehensive ERP management.
                  </div>
                ) : (
                  <div>
                    Please verify your location to continue using our advanced
                    software tools.
                  </div>
                )}
              </p>
            </div>
          </animated.div>
        </div>
      </div>
    </>
  );
};

export default Login;
