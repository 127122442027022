import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if token exists in localStorage
    const token = localStorage.getItem("token");
    const User = JSON.parse(localStorage.getItem("userInfo")) || [];

    // If token is not found, redirect to login
    if (!token && !User) {
      navigate("/login");
    }
  }, []);

  return (
    <div className='flex justify-center items-center h-screen'>
      <div className='text-center'>
        <h1 className='text-4xl font-bold'>404 - Page Not Found</h1>
        <p className='text-lg mt-4'>
          The page you are looking for does not exist.
        </p>
        <button
          onClick={() => navigate("/login")}
          className='mt-6 px-4 py-2 bg-blue-500 text-white rounded-lg'
        >
          Go Back to Login Page
        </button>
      </div>
    </div>
  );
};

export default PageNotFound;
