import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";
import axios from 'axios';
import { BASE_URI } from '../../utils/BaseURL';

const DistributorPermission = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    parties: [],
    formAccess: []
  });
  const [partyNames, setPartyNames] = useState([]);
  const [formAccessData, setFormAccessData] = useState([]);
  const [errors, setErrors] = useState({
    username: '',
    password: ''
  });
  const [selectedFormAccess, setSelectedFormAccess] = useState([]);
  
  const navigate = useNavigate();
  const token = Cookies.get("token");
  const targetRoute = "Sales Register";
  const menuValue = JSON.parse(localStorage.getItem("Menu"));
  const Depot = JSON.parse(localStorage.getItem("Depo"));
  const selectedLocation = localStorage.getItem("selectedLocation");
  const targetPage = menuValue.find((item) => item.FormName === targetRoute);
  const FormKey = targetPage ? targetPage.FormKey : null;
  const itemFromLocalStorage = JSON.parse(localStorage.getItem('userInfo'));
  const UserName = localStorage.getItem('UserName');
  const LogId = itemFromLocalStorage[0].LogID;
  const typeOfUser = itemFromLocalStorage[0].UserType;
  
  let DPCode = Depot.find(depot => depot.DPName === selectedLocation)?.DPCode;

  const config = {
    headers: {
      "Content-Type": "application/json",
      token: token,
    },
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e, type) => {
    const { value, checked } = e.target;

    if (type === 'parties') {
      if (value === 'All') {
        setFormData({
          ...formData,
          [type]: checked ? partyNames.map(party => party.value) : []
        });
      } else {
        setFormData({
          ...formData,
          [type]: checked
            ? [...formData[type], value]
            : formData[type].filter((item) => item !== value)
        });
      }
    } else {
      if (value === 'All') {
        setFormData({
          ...formData,
          [type]: checked ? formAccessData.map(form => form.value) : []
        });
      } else {
        setFormData({
          ...formData,
          [type]: checked
            ? [...formData[type], value]
            : formData[type].filter((item) => item !== value)
        });
      }
      setSelectedFormAccess(formData.formAccess);
    }
  };

  const validateForm = () => {
    let valid = true;
    let usernameError = '';
    let passwordError = '';

    if (!formData.username) {
      usernameError = 'Username is required';
      valid = false;
    }

    if (!formData.password) {
      passwordError = 'Password is required';
      valid = false;
    } else if (formData.password.length < 6) {
      passwordError = 'Password must be at least 6 characters';
      valid = false;
    }

    setErrors({ username: usernameError, password: passwordError });
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Check if a SubCategory 0 item is selected without any SubCategory 1 items
    const subCategory0Selected = formData.formAccess.some(
      form => formAccessData.find(data => data.value === form && data.SubCategory === 0)
    );
    const subCategory1Checked = formData.formAccess.some(
      form => formAccessData.find(data => data.value === form && data.SubCategory === 1)
    );

    if (subCategory0Selected && !subCategory1Checked) {
      alert('You must select at least one SubCategory 1 item before selecting SubCategory 0 items.');
      return;
    }

    if (validateForm()) {
      console.log('Form Data after Validation:', formData);
    }
  };

  useEffect(() => {
    const fetchParties = async () => {
      try {
        const requestBodyParties = {
          LogID: LogId,
          FormKey: 303,
          Depo: DPCode,
          AgentName: "",
        };
        const responseParties = await axios.post(`${BASE_URI}/parties`, requestBodyParties, config);
        const partyNamesData = responseParties.data.resultWebPartyList.map(party => ({
          value: party.LName,
          label: party.LName
        }));
        setPartyNames([{ value: 'All', label: 'All' }, ...partyNamesData]);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          alert('Your Token is expired. Please log in again!');
          navigate('/login');
        } else {
          alert('Something went wrong. Please try again later.');
        }
        console.error("Error fetching parties:", error);
      }
    };

    const fetchFormAccess = async () => {
      try {
        const requestBody= {
          LogID: LogId,
          FormKey: 0,
          Depo: DPCode,
        };
        const response = await axios.post(`${BASE_URI}/auth/setNewBranch`, requestBody, config);
        const formAccessData = response.data.formDetails.map(form => ({
          value: form.FormName,
          label: form.FormName,
          SubCategory: form.SubCategory
        }));
        setFormAccessData([{ value: 'All', label: 'All' }, ...formAccessData]);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          alert('Your Token is expired. Please log in again!');
          navigate('/login');
        } else {
          alert('Something went wrong. Please try again later.');
        }
        console.error("Error fetching form access:", error);
      }
    };

    fetchParties();
    fetchFormAccess();
  }, []);

  return (
    <div className="p-2">
      <h1 className="text-lg text-center font-semibold bg-gray-50 shadow-lg rounded-lg p-1">User Setup</h1>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-2 gap-4">
          {/* Input for Username */}
          <div>
            <label>UserName</label>
            <input
              type="text"
              name="username"
              placeholder="Username"
              value={formData.username}
              onChange={handleInputChange}
              className="border p-2 w-full"
            />
            {errors.username && <p className="text-red-500 text-sm">{errors.username}</p>}
          </div>

          {/* Input for Password */}
          <div>
            <label>Password</label>
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleInputChange}
              className="border p-2 w-full"
            />
            {errors.password && <p className="text-red-500 text-sm">{errors.password}</p>}
          </div>
        </div>

        <div className="mt-4 bg-gray-100 p-2 shadow-xl ">
          <div className="grid grid-cols-2 gap-2 h-[500px] overflow-y-auto">
          {/* Form Access Checkboxes */}
            <div className=''>
              <p className="font-semibold text-lg">Select Form Access</p>
              <div className="flex flex-col">
                {formAccessData.map((form) => (
                  <label key={form.value} className={form.SubCategory === 1 ? 'text-red-500 font-bold' : ''}>
                    <input
                      type="checkbox"
                      value={form.value}
                      onChange={(e) => handleCheckboxChange(e, 'formAccess')}
                      checked={formData.formAccess.includes(form.value)}
                      className="mr-2"
                    />
                    {form.label}
                  </label>
                ))}
              </div>
            </div>

            {/* Party Checkboxes */}
            <div className=''>
              <p className="font-semibold text-lg">Select Parties</p>
              <div className="flex flex-col">
                {partyNames.map((party) => (
                  <label key={party.value}>
                    <input
                      type="checkbox"
                      value={party.value}
                      onChange={(e) => handleCheckboxChange(e, 'parties')}
                      checked={formData.parties.includes(party.value)}
                      className="mr-2"
                    />
                    {party.label}
                  </label>
                ))}
              </div>
            </div>

            
          </div>
        </div>

        {/* Submit Button */}
        <div className="mt-4">
          <button
            type="submit"
            className="bg-blue-500 text-white p-2 rounded w-full"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default DistributorPermission;
