import React, { useState, useEffect } from "react";
import Table from "../../components/Datatable/SearchFilterTable";
import Dropdown from "../../components/DynamicComponents/DynamicDropdown";
import DynamicButton from "../../components/DynamicComponents/DynamicButton";
import DynamicDatePicker from "../../components/DynamicComponents/DynamicDatePicker";
import InputDropdown from "../../components/DynamicComponents/DynamicInputDropdown";
import axios from "axios";
import { BASE_URI } from "../../utils/BaseURL";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Loader from "../../utils/Loader";
import { MdGetApp } from "react-icons/md";
import jsPDF from "jspdf";
import "jspdf-autotable";

const columns = [
  {
    Header: "S No",
    Cell: ({ row }) => (
      <div style={{ textAlign: "center" }}>
        {row.original.id === "openingBalance" ||
        row.original.id === "lastBalance"
          ? ""
          : row.index + 1}
      </div>
    ),
    isInitiallyVisible: true,
  },
  {
    Header: "No",
    accessor: "VouNo",
    Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
    isInitiallyVisible: false,
  },
  {
    Header: "Date",
    accessor: "VDate",
    Cell: ({ value }) => {
      const date = new Date(value);
      if (isNaN(date.getTime())) {
        return "";
      }
      const formattedDate = `${date.getDate().toString().padStart(2, "0")}/${(
        date.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}/${date.getFullYear()}`;
      return formattedDate;
    },
    isInitiallyVisible: true,
  },
  {
    Header: "Particulars",
    accessor: "LName",
    Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    isInitiallyVisible: true,
  },
  {
    Header: "Depot",
    accessor: "DpAbbr",
    Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
    isInitiallyVisible: false,
  },
  {
    Header: "Debit",
    accessor: "DrAmt",
    Cell: ({ value }) => (
      <div style={{ textAlign: "right" }}>
        {!isNaN(parseFloat(value)) ? parseFloat(value).toFixed(2) : ""}
      </div>
    ),
    isInitiallyVisible: true,
  },
  {
    Header: "Credit",
    accessor: "CrAmt",
    Cell: ({ value }) => (
      <div style={{ textAlign: "right" }}>
        {!isNaN(parseFloat(value)) ? parseFloat(value).toFixed(2) : ""}
      </div>
    ),
    isInitiallyVisible: true,
  },
  {
    Header: "Balance",
    accessor: "Balance",
    Cell: ({ value }) => (
      <div style={{ textAlign: "right" }}>
        {Math.abs(parseFloat(value)).toFixed(2)}
      </div>
    ),
    isInitiallyVisible: true,
  },
  {
    Header: "",
    accessor: "extra",
    Cell: ({ row }) => {
      const { id, Balance } = row.original;

      // Do not display "Dr" or "Cr" for openingBalance or lastBalance rows
      if (id === "openingBalance" || id === "lastBalance") {
        return null; // or return an empty string "" if you prefer
      }

      const balance = parseFloat(Balance) || 0;
      return (
        <div style={{ textAlign: "center" }}>{balance >= 0 ? "Dr" : "Cr"}</div>
      );
    },
    isInitiallyVisible: true,
  },
  {
    Header: "Remark",
    accessor: "Remark1",
    Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    isInitiallyVisible: false,
  },
];

const Ledger = () => {
  const [filter, setFilter] = useState("");
  const [selectedDate, setSelectedDate] = useState([new Date(), new Date()]);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [resultWebPartyList, setResultWebPartyList] = useState([]);
  const [LCode, setLCode] = useState("");
  const [ledgerDataResult, setLedgerDataResult] = useState([]);
  const [balAmtResult, setBalAmtResult] = useState([]);

  // Basic Information For API's
  let targetRoute = "Ledger";
  let menuValue = JSON.parse(localStorage.getItem("Menu"));
  let Depot = JSON.parse(localStorage.getItem("Depo"));
  let selectedLocation = localStorage.getItem("selectedLocation");
  const navigate = useNavigate();

  const targetPage = menuValue.find((item) => item.FormName === targetRoute);
  const FormKey = targetPage ? targetPage.FormKey : null;

  const token = Cookies.get("token");
  var LogId;
  var DPCode;
  var typeOfUser;

  Depot.forEach((depot) => {
    if (depot.DPName === selectedLocation) {
      DPCode = depot.DPCode;
    }
  });
  const itemFromLocalStorage = JSON.parse(localStorage.getItem("userInfo"));
  LogId = itemFromLocalStorage[0].LogID;
  typeOfUser = itemFromLocalStorage[0].UserType;

  // Config for API's
  const config = {
    headers: {
      "Content-Type": "application/json",
      token: token,
    },
  };
  const BASE_URL = BASE_URI;

  const dropdownOptions1 = ["All"];

  useEffect(() => {
    const fetchPartiesData = async () => {
      try {
        const requestBody = {
          LogID: LogId,
          FormKey: FormKey,
          Depo: DPCode,
          AgentName: "",
        };

        const response = await axios.post(
          `${BASE_URL}/parties`,
          requestBody,
          config
        );
        const resultWebPartyList = response.data.resultWebPartyList;
        setResultWebPartyList(resultWebPartyList); // Store the result
        const partyNamesData = resultWebPartyList.map((row) => row.LName);
        const uniquePartyNames = Array.from(new Set(partyNamesData));
        setDropdownOptions(uniquePartyNames);
      } catch (error) {
        if (error.response.status === 401) {
          alert("Your Token is expired Please Login Again!!!");
          navigate("/login");
        } else {
          alert("Something went wrong!!!");
        }
      }
    };

    fetchPartiesData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowLoader(true);
    if (typeOfUser !== 3 && filter === "") {
      alert("Please Select any one Party.");
      setShowLoader(false);
      return;
    }

    if (!selectedDate || selectedDate.length < 2) {
      alert("Please select both start and end dates.");
      setShowLoader(false);
      return;
    }

    const [startDate, endDate] = selectedDate;

    // Validate Dates
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      alert("Invalid date selected.");
      setShowLoader(false);
      return;
    }

    const year = startDate.getFullYear();
    const month = String(startDate.getMonth() + 1).padStart(2, "0");
    const day = String(startDate.getDate()).padStart(2, "0");
    const formattedStartDate = `${year}${month}${day}`;

    const endDateYear = endDate.getFullYear();
    const endDateMonth = String(endDate.getMonth() + 1).padStart(2, "0");
    const endDateDay = String(endDate.getDate()).padStart(2, "0");
    const formattedEndDate = `${endDateYear}${endDateMonth}${endDateDay}`;

    try {
      const requestData = {
        LogID: LogId,
        Depo: DPCode,
        PartyCode: typeOfUser === 3 ? resultWebPartyList[0].LCode : LCode,
        StartDate: formattedStartDate,
        EndDate: formattedEndDate,
      };

      const response = await axios.post(
        `${BASE_URL}/ledger`,
        requestData,
        config
      );

      const balAmtResult = response.data.balAmtResult;
      const ledgerDataResult = response.data.ledgerDataResult.map(
        (row, index) => ({
          ...row,
          id: index + 1,
        })
      );

      // Calculate balance dynamically
      let runningBalance = parseFloat(balAmtResult[0]?.BalAmt) || 0;
      let totalDebit = 0;
      let totalCredit = 0;
      const updatedLedgerData = ledgerDataResult.map((row) => {
        const debit = parseFloat(row.DrAmt) || 0;
        const credit = parseFloat(row.CrAmt) || 0;
        totalDebit += debit;
        totalCredit += credit;
        runningBalance += debit - credit;
        return {
          ...row,
          Balance: runningBalance.toFixed(2),
        };
      });
      console.log(balAmtResult[0]?.BalAmt > 0);
      console.log(runningBalance);
      // Add Opening Balance row
      const openingBalanceRow = {
        id: "openingBalance",
        VouNo: "",
        VDate: "",
        LName: "Opening Balance",
        DpAbbr: "",
        DrAmt: "",
        CrAmt: "",
        extra:
          balAmtResult[0]?.BalAmt > 0
            ? "Dr"
            : balAmtResult[0]?.BalAmt < 0
            ? "Cr"
            : "-", // Adjusting to show "Dr", "Cr", or empty
        Balance: balAmtResult?.[0]?.BalAmt
          ? balAmtResult[0]?.BalAmt.toFixed(2)
          : "0.00",
        Remark1: "",
      };

      // Add Last Balance row with totals
      const lastBalanceRow = {
        id: "lastBalance",
        VouNo: "",
        VDate: "",
        LName: "Last Balance",
        DpAbbr: "",
        DrAmt: totalDebit.toFixed(2),
        CrAmt: totalCredit.toFixed(2),
        Balance: runningBalance.toFixed(2),
        Remark1: "",
        extra: runningBalance >= 0 ? "Dr" : "Cr", // Fixed assignment for the Type
      };

      // Update table data with opening and last balance rows
      const combinedData = [
        openingBalanceRow,
        ...updatedLedgerData,
        lastBalanceRow,
      ];

      // Check if ledgerDataResult is empty
      if (ledgerDataResult.length === 0) {
        setTableData([openingBalanceRow]); // Display only opening balance row if no data available
      } else {
        setTableData(combinedData);
        setBalAmtResult(balAmtResult);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    document.title = "PTAC ERP || Ledger";

    let timer = setTimeout(() => {
      setShowLoader(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  const handleFilterChange = (value) => {
    if (!value) {
      console.error("Selected value is undefined");
      return;
    }

    setFilter(value);
    const selectedItem = resultWebPartyList.find(
      (item) => item.LName === value
    );
    if (selectedItem) {
      setLCode(selectedItem.LCode);
    } else {
      console.log("Item not found in the list");
    }
    setTableData([]); // Clear table data
  };

  const handleDateChange = (dates) => {
    setSelectedDate(dates);
    setTableData([]); // Clear table data
  };

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Return empty string if dateString is falsy

    const date = new Date(dateString);
    if (isNaN(date.getTime())) return ""; // Return empty string if date is invalid

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const totalDebit = ledgerDataResult.reduce(
    (total, row) => total + parseFloat(row.DrAmt),
    0
  );
  const totalCredit = ledgerDataResult.reduce(
    (total, row) => total + parseFloat(row.CrAmt),
    0
  );
  const grandTotalRow = {
    id: "",
    VouNo: "",
    LName: "Grand Total",
    DrAmt: totalDebit.toFixed(2),
    CrAmt: totalCredit.toFixed(2),
    Balance: opBal,
    combineRemarks: "",
  };

  const openingBalRow = {
    id: "",
    VouNo: "",
    LName: "Opening Balance",
    DrAmt: "0.00",
    CrAmt: "0.00",
    extra: balAmtResult?.[0]?.BalAmt > 0 ? "Dr" : "Cr",
    Balance: balAmtResult?.[0]?.BalAmt
      ? balAmtResult[0]?.BalAmt.toFixed(2)
      : "0.00",
    combineRemarks: "",
  };

  if (balAmtResult && balAmtResult[0] && balAmtResult[0].BalAmt !== undefined) {
    var opBal = balAmtResult[0].BalAmt.toFixed(2);
    openingBalRow.Balance = Math.abs(balAmtResult[0].BalAmt).toFixed(2);
    openingBalRow.extra = balAmtResult[0].BalAmt >= 0 ? "Dr" : "Cr";
  }

  let bal = parseFloat(opBal);

  const formattedRows = [
    openingBalRow,
    ...ledgerDataResult.map((row, index) => {
      const debit = parseFloat(row.DrAmt || 0);
      const credit = parseFloat(row.CrAmt || 0);
      // console.log(openingBalRow.extra)
      bal = debit + bal; // Update balance for the current row
      bal = bal - credit; // Update balance for the current row
      // console.log(bal)
      const extra = bal > 0 ? "Dr" : bal < 0 ? "Cr" : "";
      return {
        ...row,
        // id: index + 1, // Add serial number
        debit: debit.toFixed(2),
        credit: credit.toFixed(2),
        Balance: Math.abs(bal).toFixed(2),
        extra: extra, // Determine 'Dr' or 'Cr' based on debit or credit
      };
    }),
    grandTotalRow,
  ];

  const handleDownloadAsPDF = () => {
    // Initialize jsPDF
    const doc = new jsPDF({
      orientation: "portrait",
    });
    // Define table headers
    const headers = columns
      .filter(
        (column) =>
          column.Header !== "S No" &&
          column.Header !== "Depot" &&
          column.Header !== "No"
      )
      .map((column) => column.Header);
    columns.forEach((column) => {
      column.align = "left";
    });
    const data = tableData.map((row) => {
      return headers.map((header) => {
        switch (header) {
          case "Date":
            return formatDate(row.VDate);
          case "Particulars":
            return row.LName;
          case "Debit":
            return parseFloat(row.DrAmt)
              ? parseFloat(row.DrAmt).toFixed(2)
              : "0.00"; // Format Debit to 2 decimal places and convert to string
          case "Credit":
            return parseFloat(row.CrAmt)
              ? parseFloat(row.CrAmt).toFixed(2)
              : "0.00"; // Format Credit to 2 decimal places and convert to string
          case "Balance":
            return Math.abs(row.Balance).toFixed(2);
          case "":
            return row.Balance >= 0 ? "Dr" : "Cr";
          case "Remark":
            return row.Remark1;
          default:
            return row[header];
        }
      });
    });
    // console.log(rows)
    // console.log(localStorage.getItem("userInfo"))

    const companyName = localStorage.getItem("cName");
    const title = "Ledger";
    const startDate = selectedDate[0];
    const endDate = selectedDate[1];
    const companyLocation = localStorage.getItem("selectedLocation");
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Adding 1 because January is 0
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedStartDate = `${day}-${month}-${year}`;

    const center = doc.internal.pageSize.width / 2;
    const rightPosition = doc.internal.pageSize.width - 15; // Adjust as needed
    // Bold
    doc.setFont("helvetica", "semibold"); // Set the font to bold variant of Helvetica
    doc.setFontSize(14);
    doc.text(title, center, 30, { align: "center", font: "thin" });
    // Normal
    doc.setFont("helvetica", "semibold"); // Set the font to bold variant of Helvetica
    doc.setFontSize(20);
    doc.text(`${companyName} ${companyLocation}`, center, 20, {
      align: "center",
    });
    doc.setFont("helvetica", "thin"); // Set the font to bold variant of Helvetica
    doc.setFontSize(14);
    doc.text(filter, center, 35, { align: "center" });

    doc.setFontSize(12);
    doc.text(`Date : ${formattedStartDate}`, rightPosition, 30, {
      align: "right",
    });

    doc.setFontSize(10);
    doc.text(`${formatDate(startDate)} to ${formatDate(endDate)}`, center, 40, {
      align: "center",
    });

    const quarterHeight = doc.internal.pageSize.height / 7;

    // Add table to PDF
    doc.autoTable({
      startY: quarterHeight,
      head: [headers],
      body: data,
      styles: {
        cellPadding: 1, // Reduce row padding
        lineWidth: 0.1, // Border line width
        fillColor: [255, 255, 255], // Ensure white background for the whole table
      },
      bodyStyles: {
        halign: "left", // Left-align rows by default
        fillColor: [255, 255, 255], // White text for better contrast
      },
      headStyles: {
        fillColor: "#555555", // Set header bg color to gray-600 (#555555)
        textColor: [255, 255, 255], // White text color for the header
        halign: "center", // Center-align headers
      },
      tableLineWidth: 0.1, // Outer border width
      tableLineColor: [0, 0, 0],

      didParseCell: (data) => {
        const column = data.column.dataKey;
        const rowIndex = data.row.index;
        const totalRows = data.table.body.length;
        // Set first and last row background color to gray-50 (#F9FAFB)
        if (data.section === "body") {
          if (rowIndex === 0) {
            data.cell.styles.fillColor = [249, 250, 251]; // gray-50
            data.cell.styles.textColor = [0, 0, 0]; // gray-50
          } else if (rowIndex === totalRows - 1) {
            // Set all other rows background to white
            data.cell.styles.fillColor = [249, 250, 251]; // gray-50
            data.cell.styles.textColor = [0, 0, 0]; // gray-50
          } else {
            data.cell.styles.fillColor = [255, 255, 255]; // gray-50
          }
        }

        if (column === 2 || column === 3 || column === 4) {
          data.cell.styles.halign = "right"; // Align Debit, Credit, and Balance columns to the right
        }
        if (rowIndex === 0 || rowIndex === totalRows - 1) {
          // Assuming 'Balance' is in column 4
          data.cell.styles.fontStyle = "bold"; // Set text to bold for the Balance column
        }
      },
      didDrawPage: (data) => {
        // Footer logic (unchanged)
        doc.setFontSize(10);
        doc.setFont("helvetica", "normal");
        const footerText = `Page ${data.pageCount}`;
        const textWidth = doc.getTextWidth(footerText);
        const margin = 10; // Margin from bottom
        doc.text(
          footerText,
          doc.internal.pageSize.width - textWidth - margin,
          doc.internal.pageSize.height - margin
        );
      },
    });

    // Save PDF
    doc.save("Ledger.pdf");
  };

  return (
    <div className='p-3 mx-auto bg-gray-50 rounded-lg shadow-md '>
      {showLoader && <Loader />}
      <div className='flex flex-col gap-4 mb-1'>
        <h1 className='text-xl text-center font-bold '>
          {targetPage.FormName}
        </h1>
        <form onSubmit={handleSubmit} className='space-y-2'>
          <div className='grid grid-cols-1 lg:grid-cols-5 gap-2 '>
            <InputDropdown
              options={dropdownOptions}
              value={filter}
              onChange={(value) => handleFilterChange(value)}
              label='Party Name'
              isDisabled={false}
            />
            <div className='grid grid-cols-2 gap-1 '>
              <DynamicDatePicker
                selectedDate={selectedDate[0]}
                onDateChange={(date) =>
                  handleDateChange([date, selectedDate[1]])
                }
                maxDate={new Date()}
                dateFormat='dd/MM/yyyy'
                DateLabel='Start Date'
              />
              <DynamicDatePicker
                selectedDate={selectedDate[1]}
                onDateChange={(date) =>
                  handleDateChange([selectedDate[0], date])
                }
                maxDate={new Date()}
                dateFormat='dd/MM/yyyy'
                DateLabel='End Date'
              />
            </div>
          </div>
          <div className='grid justify-end '>
            <DynamicButton variant='primary' label='Submit' />
          </div>
        </form>
      </div>
      <Table columns={columns} data={tableData} />
      {tableData.length > 0 && (
        <div className='p-2 w-full flex gap-2'>
          <button
            onClick={handleDownloadAsPDF}
            className='font-semibold text-white p-1 rounded-md bg-[#4FA9A7] w-full'
          >
            <MdGetApp
              size={20}
              className='m-auto text-red-600 hover:text-white-100'
            />
            Download Pdf
          </button>
        </div>
      )}
    </div>
  );
};

export default Ledger;
