import React, { useEffect, useState } from "react";
import Loader from "../../utils/Loader";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { BASE_URI } from "../../utils/BaseURL";
import Dropdown from "../../components/DynamicComponents/DynamicDropdown";
import Table from "../../components/Datatable/SearchFilterTable";

const RetailMaster = () => {
  // Basic Information For API's
  let targetRoute = "Retailer Master";
  let menuValue = JSON.parse(localStorage.getItem("Menu"));
  let Depot = JSON.parse(localStorage.getItem("Depo"));
  let selectedLocation = localStorage.getItem("selectedLocation");
  const navigate = useNavigate();

  const targetPage = menuValue.find((item) => item.FormName === targetRoute);
  const FormKey = targetPage ? targetPage.FormKey : null;

  const token = Cookies.get("token");
  var LogId;
  var DPCode;

  Depot.forEach((depot) => {
    if (depot.DPName === selectedLocation) {
      DPCode = depot.DPCode;
    }
  });
  const itemFromLocalStorage = JSON.parse(localStorage.getItem("userInfo"));
  LogId = itemFromLocalStorage[0].LogID;
  var typeofUser = itemFromLocalStorage[0].UserType;
  // Config for API's
  const config = {
    headers: {
      "Content-Type": "application/json",
      token: token,
    },
  };
  const BASE_URL = BASE_URI;

  const [showLoader, setShowLoader] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [distributors, setDistributors] = useState([]);
  const [selectedDistributor, setSelectedDistributor] = useState("");
  const [states, setStates] = useState([]);
  const [formData, setFormData] = useState({
    distributor: "",
    regType: "",
    gstin: "",
    retailerName: "",
    contactPerson: "",
    address1: "",
    address2: "",
    city: "",
    pincode: "",
    district: "",
    state: "",
    marriageDate: "",
    birthDate: "",
    aadharNumber: "",
    mobileNumber: "",
    email: "",
    pan: "",
    whatsappNumber: "",
    isMarried: false,
    isDOB: false,
  });

  const regTypes = [
    "Composition",
    "Consumer",
    "Regular",
    "Unregistered",
    "International",
    "UIN Holder",
  ];
  const mandatoryFields = [
    "regType",
    "retailerName",
    "city",
    "state",
    "whatsappNumber",
  ];

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let inputValue = value;

    if (name === "gstin" || name === "pan") {
      inputValue = value.toUpperCase();
    }

    // Ensure only numbers are allowed for aadhar, mobile, and whatsapp
    if (
      name === "aadharNumber" ||
      name === "mobileNumber" ||
      name === "whatsappNumber"
    ) {
      inputValue = inputValue.replace(/\D/g, "");
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : inputValue,
    }));
  };

  const validateForm = () => {
    for (const field of mandatoryFields) {
      if (!formData[field]) {
        alert(`${field.toLocaleUpperCase()} is required`);
        return false;
      }
    }

    if (formData.isMarried && !formData.marriageDate) {
      alert("Marriage Date is required");
      return false;
    }

    if (formData.isDOB && !formData.birthDate) {
      alert("Birth Date is required");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    const payload = {
      DistName: selectedDistributor.label,
      RegType: formData.regType,
      GSTIN: formData.gstin,
      RetailerName: formData.retailerName,
      CPName: formData.contactPerson,
      Add1: formData.address1,
      Add2: formData.address2,
      City: formData.city,
      Pincode: formData.pincode,
      District: formData.district,
      State: formData.state,
      AadhaarNo: formData.aadharNumber,
      MobileNo: formData.mobileNumber,
      Email: formData.email,
      PAN: formData.pan,
      WhatsappNo: formData.whatsappNumber,
      DOM: formData.isMarried ? 0 : 1,
      MarriageDate: formData.isMarried ? formData.marriageDate : null,
      DOB: formData.isDOB ? 0 : 1,
      BirthDate: formData.isDOB ? formData.birthDate : null,
      Depo: DPCode,
      LogID: LogId,
    };

    try {
      setShowLoader(true);

      const response = await axios.post(
        `${BASE_URL}/retail-data`,
        payload,
        config
      );

      alert("Retailer successfully added!");
      setShowForm(false);
      fetchTableData();
      setFormData({
        distributor: "",
        regType: "",
        gstin: "",
        retailerName: "",
        contactPerson: "",
        address1: "",
        address2: "",
        city: "",
        pincode: "",
        district: "",
        state: "",
        marriageDate: "",
        birthDate: "",
        aadharNumber: "",
        mobileNumber: "",
        email: "",
        pan: "",
        whatsappNumber: "",
        isMarried: false,
        isDOB: false,
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      alert(
        "An error occurred while submitting the form. Please try again later."
      );
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    document.title = "PTAC ERP || Retail Master";

    let timer = setTimeout(() => {
      setShowLoader(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  const fetchTableData = async () => {
    try {
      setShowLoader(true);

      const requestBody = {
        LogID: LogId,
        FormKey: FormKey,
        Depo: DPCode,
        PartyName: "",
        AgentName: "",
        ItemGroup: "",
        ItemName: "",
        Date1: "",
        Date2: "",
        RptCode: 1,
      };

      const response = await axios.post(
        `${BASE_URL}/web-reports`,
        requestBody,
        config
      );
      const data = response.data.results;
      setTableData(data);
      console.log(data);
    } catch (error) {
      console.error("Error fetching report data:", error);
      if (error.response && error.response.status === 401) {
        alert("Your Token is expired. Please login again!");
        navigate("/login");
      } else {
        alert("Something went wrong!");
      }
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    const fetchReportData = async () => {
      try {
        setShowLoader(true);

        const requestBody = {
          LogID: LogId,
          FormKey: FormKey,
          Depo: DPCode,
          PartyName: "",
          AgentName: "",
          ItemGroup: "",
          ItemName: "",
          Date1: "",
          Date2: "",
          RptCode: 0,
        };

        const response = await axios.post(
          `${BASE_URL}/web-reports`,
          requestBody,
          config
        );
        const data = response.data.results;
        const distributorOptions = response.data.results.map((d) => ({
          value: d.LCode,
          label: d.LName,
        }));
        setDistributors(distributorOptions);
        console.log(distributorOptions[0]);
        setSelectedDistributor(distributorOptions[0]);
      } catch (error) {
        console.error("Error fetching report data:", error);
        if (error.response && error.response.status === 401) {
          alert("Your Token is expired. Please login again!");
          navigate("/login");
        } else {
          alert("Something went wrong!");
        }
      } finally {
        setShowLoader(false);
      }
    };

    fetchReportData(); // Call the async function inside useEffect
    fetchTableData();
  }, []);

  const handleDistributor = (e) => {
    const selectedLCode = e.target.value;
    console.log("selectedLCode", selectedLCode);

    // Find the distributor object based on LCode
    const selectedDistributor = distributors.find(
      (distributor) => distributor.value === selectedLCode
    );
    console.log("selectedDistributor", selectedDistributor);
    setSelectedDistributor(selectedDistributor);
    if (selectedDistributor) {
      // Update formData with the LName
      setFormData((prevState) => ({
        ...prevState,
        distributor: selectedDistributor.LName,
      }));
    } else {
      console.error("Distributor not found");
    }
  };

  const toggleButton = () => {
    setShowForm(!showForm);
  };
  const columns = [
    {
      Header: "S No",
      Cell: ({ row }) => (
        <div style={{ textAlign: "center" }}>
          {row.original.isTotalRow ? "" : row.index + 1}
        </div>
      ),
      isInitiallyVisible: true,
    },
    { Header: "RegType", accessor: "RegType[0]", isInitiallyVisible: true },
    ...(typeofUser !== 3
      ? [
          {
            Header: "Distributor Name",
            accessor: "LName",
            isInitiallyVisible: true,
          },
        ]
      : []),
    { Header: "GSTIN", accessor: "GSTIN", isInitiallyVisible: false },
    {
      Header: "Retailer Name",
      accessor: "RetailerName",
      isInitiallyVisible: true,
    },
    { Header: "Contact Person", accessor: "CPName", isInitiallyVisible: true },
    { Header: "Address 1", accessor: "Address1", isInitiallyVisible: false },
    { Header: "Address 2", accessor: "Address2", isInitiallyVisible: false },
    { Header: "PinCode", accessor: "Pincode", isInitiallyVisible: true },
    { Header: "City", accessor: "City", isInitiallyVisible: true },
    { Header: "District", accessor: "District", isInitiallyVisible: false },
    { Header: "State", accessor: "State", isInitiallyVisible: true },
    { Header: "Marriage Date", accessor: "DOM", isInitiallyVisible: true },
    { Header: "Birth Date", accessor: "DOB", isInitiallyVisible: true },
    {
      Header: "Aadhar Number",
      accessor: "AadhaarNo",
      isInitiallyVisible: false,
    },
    { Header: "Email", accessor: "Email", isInitiallyVisible: false },
    { Header: "PAN", accessor: "PAN", isInitiallyVisible: false },
    { Header: "Mobile Number", accessor: "Mobile", isInitiallyVisible: true },
    {
      Header: "WhatsApp Number",
      accessor: "Whatsapp_SMS_No",
      isInitiallyVisible: true,
    },
  ];

  return (
    <div className='p-3 mx-auto rounded-lg shadow-md'>
      {showLoader && <Loader />}
      <div className='flex flex-col gap-4 mb-1'>
        <h1 className='text-lg text-center font-semibold bg-gray-50 shadow-lg rounded-lg p-1'>
          {targetPage.FormName.toUpperCase()}
        </h1>

        <div className='w-full mt-4'>
          <button
            className='w-full bg-blue-500 text-white px-4 py-2 rounded'
            onClick={toggleButton}
          >
            {showForm ? "Hide Form" : "Add New Retailer"}
          </button>
        </div>

        {showForm && (
          <form onSubmit={handleSubmit} className='space-y-4'>
            <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-2 '>
              <Dropdown
                options={distributors}
                value={formData.distributor}
                onChange={handleDistributor}
                label='Distributors'
                isDisabled={false}
              />
              <div className='ml-2 w-full'>
                <label className='block font-semibold mb-1'>
                  RegType <span className='text-red-600 '>*</span>
                </label>
                <select
                  name='regType'
                  value={formData.regType}
                  onChange={handleChange}
                  className='w-full p-1 border rounded'
                >
                  <option value=''>Select RegType</option>
                  {regTypes.map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className='block font-semibold'>GSTIN</label>
                <input
                  type='text'
                  name='gstin'
                  value={formData.gstin}
                  onChange={handleChange}
                  className='w-full p-2 border rounded'
                />
              </div>
              <div>
                <label className='block font-semibold'>
                  Retailer Name <span className='text-red-600'>*</span>
                </label>
                <input
                  type='text'
                  name='retailerName'
                  value={formData.retailerName}
                  onChange={handleChange}
                  className='w-full p-2 border rounded'
                />
              </div>
              <div>
                <label className='block font-semibold'>Contact Person</label>
                <input
                  type='text'
                  name='contactPerson'
                  value={formData.contactPerson}
                  onChange={handleChange}
                  className='w-full p-2 border rounded'
                />
              </div>
            </div>

            <div className='grid grid-cols-3 lg:grid-cols-3 gap-2 '>
              <div>
                <label className='block font-semibold'>Address - 1</label>
                <input
                  type='text'
                  name='address1'
                  value={formData.address1}
                  onChange={handleChange}
                  className='w-full p-2 border rounded'
                />
              </div>
              <div>
                <label className='block font-semibold'>Address - 2</label>
                <input
                  type='text'
                  name='address2'
                  value={formData.address2}
                  onChange={handleChange}
                  className='w-full p-2 border rounded'
                />
              </div>
              <div>
                <label className='block font-semibold'>Pincode </label>
                <input
                  type='text'
                  name='pincode'
                  value={formData.pincode}
                  onChange={handleChange}
                  className='w-full p-2 border rounded'
                  maxLength={6}
                />
              </div>
            </div>
            <div className='grid grid-cols-3 lg:grid-cols-3 gap-2 '>
              <div>
                <label className='block font-semibold'>
                  City <span className='text-red-600'>*</span>
                </label>
                <input
                  type='text'
                  name='city'
                  value={formData.city}
                  onChange={handleChange}
                  className='w-full p-2 border rounded'
                />
              </div>
              <div>
                <label className='block font-semibold'>District</label>
                <input
                  type='text'
                  name='district'
                  value={formData.district}
                  onChange={handleChange}
                  className='w-full p-2 border rounded'
                />
              </div>
              <div>
                <label className='block font-semibold'>
                  State <span className='text-red-600'>*</span>
                </label>
                <input
                  type='text'
                  name='state'
                  value={formData.state}
                  onChange={handleChange}
                  className='w-full p-2 border rounded'
                />
              </div>
            </div>
            <div className='grid grid-cols-2 lg:grid-cols-4 gap-2 '>
              <div className='md:col-span-2 m-2'>
                <label className='block font-semibold'>
                  <input
                    type='checkbox'
                    name='isMarried'
                    checked={formData.isMarried}
                    onChange={handleChange}
                    className='mr-2'
                  />
                  Date of Marriage
                </label>
                {formData.isMarried && (
                  <input
                    type='date'
                    name='marriageDate'
                    value={formData.marriageDate}
                    onChange={handleChange}
                    className='w-full p-1 border rounded mt-2 '
                  />
                )}
              </div>
              <div className='md:col-span-2 m-2'>
                <label className='block font-semibold'>
                  <input
                    type='checkbox'
                    name='isDOB'
                    checked={formData.isDOB}
                    onChange={handleChange}
                    className='mr-2'
                  />
                  Date of Birth
                </label>
                {formData.isDOB && (
                  <input
                    type='date'
                    name='birthDate'
                    value={formData.birthDate}
                    onChange={handleChange}
                    className='w-full p-1 border rounded mt-2'
                  />
                )}
              </div>
            </div>
            <div className='grid grid-cols-3 lg:grid-cols-3 gap-2 '>
              <div>
                <label className='block font-semibold'>Aadhar Number</label>
                <input
                  type='text'
                  name='aadharNumber'
                  value={formData.aadharNumber}
                  onChange={handleChange}
                  className='w-full p-2 border rounded'
                  maxLength={12} // Limit length to 12 digits
                />
              </div>
              <div>
                <label className='block font-semibold'>Email</label>
                <input
                  type='email'
                  name='email'
                  value={formData.email}
                  onChange={handleChange}
                  className='w-full p-2 border rounded'
                />
              </div>
              <div>
                <label className='block font-semibold'>PAN</label>
                <input
                  type='text'
                  name='pan'
                  value={formData.pan}
                  onChange={handleChange}
                  className='w-full p-2 border rounded'
                />
              </div>
            </div>
            <div className='grid grid-cols-2 lg:grid-cols-2 gap-2 '>
              <div>
                <label className='block font-semibold'>Mobile Number</label>
                <input
                  type='tel'
                  name='mobileNumber'
                  value={formData.mobileNumber}
                  onChange={handleChange}
                  className='w-full p-2 border rounded'
                  maxLength={12} // Limit length to 10 digits
                />
              </div>
              <div>
                <label className='block font-semibold'>
                  Whatsapp Number <span className='text-red-600'>*</span>
                </label>
                <input
                  type='text'
                  name='whatsappNumber'
                  value={formData.whatsappNumber}
                  onChange={handleChange}
                  className='w-full p-2 border rounded'
                  maxLength={10} // Limit length to 10 digits
                />
              </div>
            </div>

            <div className='text-right'>
              <button
                type='submit'
                className='px-4 py-2 bg-blue-500 text-white rounded'
              >
                Submit
              </button>
            </div>
          </form>
        )}

        <Table columns={columns} data={tableData} />
      </div>
    </div>
  );
};

export default RetailMaster;
