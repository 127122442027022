import React, { useState } from "react";
import { QRCodeCanvas } from "qrcode.react"; // Use QRCodeCanvas for generating the QR code
import Barcode from "react-barcode";

const QrBarcodeGenerator = () => {
  const [inputValue, setInputValue] = useState("");
  const [generatedValue, setGeneratedValue] = useState("");

  const handleGenerate = () => {
    // Ensure the input is valid (minimum 5 characters)
    if (inputValue.length >= 5) {
      setGeneratedValue(inputValue);
    } else {
      alert("Input must be at least 5 characters long!");
    }
  };

  return (
    <div className='grid grid-cols-3 gap-6 bg-gray-100 p-6 w-full'>
      {/* Input Section */}
      <div className='col-span-1'>
        <div className='mb-4'>
          <input
            type='text'
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder='Enter at least 5 characters'
            className='border border-gray-400 p-2 rounded w-full'
          />
        </div>

        {/* Button to Generate */}
        <button
          onClick={handleGenerate}
          className='bg-blue-500 text-white p-2 rounded w-full'
        >
          Generate QR Code & Barcode
        </button>
      </div>

      {/* QR Code Section */}
      <div className='col-span-1 flex flex-col items-center'>
        {generatedValue && (
          <>
            <h3 className='font-semibold text-lg mb-4'>QR Code:</h3>
            <QRCodeCanvas value={generatedValue} size={150} />
          </>
        )}
      </div>

      {/* Barcode Section */}
      <div className='col-span-1 flex flex-col items-center'>
        {generatedValue && (
          <>
            <h3 className='font-semibold text-lg mb-4'>Barcode:</h3>
            <Barcode value={generatedValue} />
          </>
        )}
      </div>
    </div>
  );
};

export default QrBarcodeGenerator;
